import React from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Seo from '../components/seo';
import Header from '../components/Header';
import Footer from '../components/footer';
import Layout from '../components/layout';
import '../sass/main.scss';
import Nominees from '../components/BlockLibrary/HamboneAward/Nominees';
import Blocks from '../components/blocks';

class NomineesTemplate extends React.Component {
     componentDidMount() {
          const brower = typeof window !== `undefined`;

          if (brower) {
               var getOmValue = localStorage.getItem('Petinsurance_OM');
               this.props.setTrackingCode(getOmValue);
               var getPhoneValue = localStorage.getItem('Petinsurance_TrackingPhone');
               this.props.setTrackingPhone(getPhoneValue);
          }
          const trustpilotScript = document.createElement('script');
          trustpilotScript.type = 'text/javascript';
          trustpilotScript.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
          trustpilotScript.async = true
          document.head.appendChild(trustpilotScript)
          trustpilotScript.onload = function () {
               var trustbox = document.getElementsByClassName('trustpilot-widget');
               window.Trustpilot.loadFromElement(trustbox);
          };
     }

     render() {
          const nominee = get(this.props, 'data.contentfulHamboneNominees');
          const nomineesPet = get(this.props, 'data.allContentfulHamboneNomineesPet.nodes');

          // Sort nomineesPet by descending creation date and created at
          nomineesPet.sort((a, b) => {
               let dateA = a.creationDate || a.createdAt;
               let dateB = b.creationDate || b.createdAt;

               return new Date(dateB).getTime() - new Date(dateA).getTime();
          })

          const path = get(this.props, '*');
          // const seoTitleTag = nominee.seoTitleTag ? nominee.seoTitleTag : `${nominee.breadcrumbValue} | Pet Health Insurance & Tips`;
          // const seoMetaDescription = nominee.seoMetaDescription ? nominee.seoMetaDescription.seoMetaDescription : nominee.shortDescription;

          const trackingCodeDefault = get(this.props, 'data.getTrackingCodeDefault');
          const trackingCode = nominee.trackingCode != null ? nominee.trackingCode : trackingCodeDefault.trackingCode;
          const defaultSeoTitle = `Hambone Award ${nominee.nomineesYear} Nominees | Unusual Pet Claims of ${nominee.nomineesYear}`;
          const defaultSeoMetaDescription = `Discover the inspiring tales of ${nominee.nomineesYear}'s Nationwide Hambone Award nominees. Learn more about the brave pets survived incredible accidents and illnesses.`;

          return (
               <Layout>
                    <Seo
                         pageName={nominee.omniturePageNameLegacy || nominee.contentName}
                         specificPhone={nominee.specificPhone}
                         trackingCode={trackingCode}
                         title={nominee.seoTitleTag || defaultSeoTitle}
                         path={path}
                         canonical={nominee.seoCanonicalUrl ? nominee.seoCanonicalUrl.seoCanonicalUrl : ''}
                         description={nominee.seoMetaDescription?.seoMetaDescription || defaultSeoMetaDescription}
                         robots={nominee.robotsDirective ? nominee.robotsDirective.robotsDirective : ''}
                         image={nominee.socialImage ? nominee.socialImage : nominee.featuredImage}
                         category={nominee}
                         // hasBreadcrumb={true}
                         showAntiFlickerSnippet={nominee.showAntiFlickerSnippet}
                    />
                    <Header header={nominee.header}></Header>
                    <main id="main-content" role="main">
                         <Nominees nominee={nominee} nomineesPet={nomineesPet} />
                         {nominee.rows && <Blocks blocks={nominee.rows}></Blocks>}
                    </main>
                    <Footer footer={nominee.footer}></Footer>
               </Layout>
          );
     }
}

const mapDispatchToProps = (dispatch) => {
     return {
          setInfoArticle: (data) => dispatch({ type: `GET_INFO_ARTICLE`, payload: data }),
          setTrackingCode: (trackingCode) => dispatch({ type: `TRACKING_CODE`, payload: trackingCode }),
          setTrackingPhone: (trackingPhone) => dispatch({ type: `TRACKING_PHONE`, payload: trackingPhone })
     };
};
const mapStateToProps = (state) => {
     return {
          state
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(NomineesTemplate);

export const pageQuery = graphql`
     query NomineesTemplateBySlug($nomineesYear: Int!) {
          contentfulHamboneNominees(nomineesYear: { eq: $nomineesYear }) {
               ...ContentfulHamboneNomineesFields
          }
          allContentfulHamboneNomineesPet(sort: { fields: createdAt, order: DESC }, filter: { nomineesOfTheYear: { nomineesYear: { eq: $nomineesYear } } }) {
               nodes {
                    id
                    slug
                    nomineesPetName
                    nomineesPetImage {
                         ...ContentfulImageFields
                    }
                    nomineesPetFeaturedImage {
                         ...ContentfulImageFields
                    }
                    nomineesOfTheYear {
                         nomineesYear
                    }
                    createdAt
                    creationDate
               }
          }
          getTrackingCodeDefault: contentfulPage(contentful_id: { eq: "wChsWYdrCJ5doI8QdVkBk" }) {
               id
               trackingCode
          }
     }
`;
